<template>
    <div>
        <Content :paddingBottom="true" :paddingTop="true">
            <PageHeader title="Artists"/>
            <Grid
                v-if="!artists"
                :size="$store.state.settings.smallItems ? 4 : 3"
            >
                <GridItem
                    v-for="i in Array(9).fill(0).map((_, i) => i * i)"
                    :key="i"
                    url="#"
                />
            </Grid>
            <Grid
                v-else
                :size="$store.state.settings.smallItems ? 4 : 3"
            >
                <GridItem
                    v-for="artist in artists"
                    :key="artist.id"
                    :url="`/artists/${artist.attributes.url_key}`"
                    :image="$getArtistImage(artist, 'medium')"
                    :title_one="artist.attributes.title"
                />
            </Grid>
        </Content>
        <slot name="footer"/>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import Content from '@/components/Content'
    import Grid from '@/components/Grid'
    import GridItem from '@/components/GridItem'
    import { artistsQuery } from '@/clients/strapi'

    export default {
        name: 'Artists',

        components: {
            PageHeader,
            Content,
            Grid,
            GridItem
        },

        apollo: {
            artists: {
                query: artistsQuery,
                variables() {
                    return {
                        limit: 999
                    }
                },
                update: data => data.artists.data
            }
        },

        computed: {
            artistRoutes() {
                if(this.artists){
                    var routes = []
                    this.artists.forEach(function(artist){
                        routes.push(artist.attributes.url_key)
                    })
                    return routes
                }
                return null
            }
        },

        watch: {
            artistRoutes: {
                handler() {
                    if(this.artistRoutes) {
                        this.$store.commit('setArtistRoutes', this.artistRoutes)
                    }
                }
            }
        },

        metaInfo: {
            title: '● Artists'
        }
    }
</script>
